import React, { useState, useEffect } from 'react'
import classes from '../styles/Playscreen.module.css'
import { useGetplayvideosQuery } from '../redux/api/apiSlice'
import { useSelector } from 'react-redux'
import { UserAuth } from '../context/AuthContext'
import { Loader, Text, Button, Center, Modal, Notification, Stack, Group, Card } from '@mantine/core';
import ReactPlayer from 'react-player/lazy'
import { FaArrowLeft } from "react-icons/fa";
import { FiCamera } from "react-icons/fi";
import { BsPersonCircle } from "react-icons/bs";
import { FiAlertCircle } from "react-icons/fi";
import { useDisclosure } from '@mantine/hooks';
import { FaVideo } from "react-icons/fa6";
import WebcamPlayVideo from '../components/WebcamPlayVideo';
import { GoRocket } from "react-icons/go";
import { FaRegCirclePlay } from "react-icons/fa6";
import { Trans } from '@lingui/macro'
import { FaImage } from "react-icons/fa";
import VideoPlayer from '../components/VideoPlayer'
import ScrollContainer from '../components/PlayReels/Scroller.jsx'





const Play = () => {
  const [playvideos, setPlayvideos] = useState([])
  const token = useSelector((state) => state?.user.token)
  const candidateId = localStorage.getItem('candidateId')
  const { user } = UserAuth()
  const [myplayvideos, setMyPlayvideos] = useState([])
  const [loading, setloading] = useState(true)
  const [id, setId] = useState(null)
  const [videoData, setVideoData] = useState([])
  const [show, setshow] = useState(false)
  const [opened3, { open: open3, close: close3 }] = useDisclosure(false);
  const [opened4, { open: open4, close: close4 }] = useDisclosure(false);
  const [loadingPlay, { open: openLoading, close: closeLoading }] = useDisclosure(false);
  const [showAlertSuccess, setShowAlertSuccess] = useState(false)
  const [openedPlay, setOpenedPlay] = useState(false);
  const [myPlayShow, setMyPlayShow] = useState(false)
  var languageOrigin = localStorage.getItem('languageOrigin');

  const [opened, { open, close }] = useDisclosure(false);


  // const [uploadPlayVideo] = useUploadPlayVideoMutation()

  const [file, setFile] = useState(null);


  const { data: playvideosdata, isLoading, isError, isFetching, refetch } = useGetplayvideosQuery({
    skip: !token
  })

  //   const {data: playmyvideosdata, ismyLoading, ismyError, ismyFetching, refetch: myVideorefetch } = useGetMyplayvideosQuery({ candidateId }, {
  //     skip:!token
  // })

  //////////////////////////////



  useEffect(() => {

    if (user) {
      fetchMyPlayData()
    }

    // if (playmyvideosdata) {
    //   setMyPlayvideos(playmyvideosdata)
    // }
  }, [user]);

  const fetchMyPlayData = async () => {
    const url = import.meta.env.VITE_BASE_URL + '/candidate/multiple-video';
    //console.log("url lelo", url)
    try {
      const response = await fetch(`${url}?candidateId=${candidateId}&page=0&size=100`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${user?.accessToken}`,
          "Accept": 'application/json'
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok in playData');
      }
      const playmyvideosdata = await response.json();
      // console.log("playmypvideo--", playmyvideosdata)
      setMyPlayvideos(playmyvideosdata)
      setloading(false)
      // setProData(playmyvideosdata);

    } catch (error) {
      console.error('Error fetching profile data:', error);
    }
  };


  //////////////////////////////

  //console.log("aaaqqqqqqqqqqqqewwwwww789", playmyvideosdata)


  useEffect(() => {
    if (token) {
      refetch();
    }
  }, [token, refetch]);


  useEffect(() => {
    if (playvideosdata) {
      setPlayvideos(playvideosdata)
      setloading(false)
    }
  }, [playvideosdata]);






  const playVideo = (id, videoData) => {
    setId(id)
    setVideoData(videoData)
    setshow(true)
  }


  const modalOpen = (id, videoData) => {
    setId(id)
    setVideoData(videoData)
    setshow(true)
    open();
  }

  const handleClick = () => {
    close3()
    close4()
    setloading(true)
    fetchMyPlayData()
    console.log("kkkkkppppppppppppp")
  }

  const handleFileChange2 = (e) => {
    setFile(e.target.files[0]);
  };



  const handleUploadVideo = async () => {

    const url = import.meta.env.VITE_BASE_URL + '/candidate';
    //openLoadinimport ScrollContainer from './../services/Scroller';
    if (!file) {
      alert('Please select a video file');
      return;
    }
    close4()
    close3()
    const formData = new FormData();
    formData.append('video', file);
    // console.log(formData, candidateId,"poooooooowwwwww")

    try {
      // uploadPlayVideo(formData, candidateId).then((res) => {
      //       // alert('succesfully uploaded' + JSON.stringify(res.data))
      //       console.log("after uplaod--" + JSON.stringify(res))
      //       setFile(null)
      //      // closeLoading()
      //   }).catch((err) => {
      //       console.log('ffffff', err)
      //   })

      const response = await fetch(`${url}/${candidateId}/multiple-video`, {
        method: 'POST',
        body: formData,
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.ok) {
        //setShowAlertSuccess(true)
        closeLoading()
        setFile(null)
        console.log('Video uploaded successfully');
        setShowAlertSuccess(true)
        fetchMyPlayData()
      }

    } catch (error) {
      console.error('Error uploading video:', error);
      alert('An error occurred. Please try again.');
    }
  };



  const myPlayVideo = () => {
    // console.log("ascxcdfwdwcdvsdcsssssss", myPlayShow)
    setMyPlayShow(!myPlayShow)
    fetchMyPlayData()
    //setMyPlayShow(true)
  }

  const openDisclaimer = () => {
    setOpenedPlay(!openedPlay)
  }

  const closePlay = () => {
    setOpenedPlay(false);
  }



  return (
    <div style={{ position: "relative", background: "#F6F6F6" }}>


      {showAlertSuccess && (
        <Notification
          color='#eee'
          onClose={() => setShowAlertSuccess(false)}
          style={{
            backgroundColor: "#2aa63d",
            color: "#eee",
            position: "fixed",
            width: "100%",
            maxWidth: "600px",
            zIndex: 9999,
            top: 60
          }}
        >
          <span style={{ color: "#eee", fontSize: "1rem" }}>
            <Trans>Video uploaded successfully</Trans>
          </span>
        </Notification>
      )}

      <Modal opened={opened4} centered onClose={close4}>
        <Center style={{ alignContent: "center", alignItems: "center" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {
              !file && <Center>
                <Button style={{ width: "100%", marginLeft: "20px", marginRight: "20px" }} mb={"10px"} color='purple' onClick={open3}><FaVideo style={{ marginRight: "10px" }} /><Trans>Upload from Camera</Trans></Button>
              </Center>
            }

            <Center>

              {
                !file && <label style={{ width: "100%", marginLeft: "20px", marginRight: "20px", fontSize: "15px", fontWeight: "500" }} className={classes.upload_image_btn}>
                  <input style={{ opacity: "0", width: "5%" }} type="file" accept="video/*" onChange={handleFileChange2}></input>
                  <FaImage style={{ marginRight: "10px" }} /><Trans>Upload from Gallery</Trans>
                </label>
              }

            </Center>
            {
              file && <Button onClick={handleUploadVideo} ml={"10px"} color='purple' disabled={!file} >
                <Trans>Upload Video</Trans>
              </Button>
            }
          </div>
        </Center>
      </Modal>

      <Modal opened={opened3} onClose={close3} fullScreen>
        <div >
          <WebcamPlayVideo handleClick={handleClick} />
        </div>
      </Modal>


      {/* //////////////////Open Play/////////////////////// */}

      <Modal
        withCloseButton={true}
        opened={openedPlay}
        onClose={() => setOpenedPlay(false)}
        centered
      >

        <div style={{ marginLeft: "10px", marginRight: "10px", marginBottom: "10px" }} >


          <Text style={{ color: "#1F7A8C", textAlign: "center" }} fw={500} size="xl" mb="xs">
            <Trans>ShramIN - Play feature</Trans>
            {/* श्रमिन - प्ले फीचर */}
          </Text>

          <div >
            <Text>
              <Trans>This app lets you make short videos to show who you are and what you can do. This can help you get a better job!</Trans>
              {/* आपके लिए लघु वीडियो के माध्यम से स्वयं को प्रचारित करने का एक मंच। यह आपकी प्रोफ़ाइल, कौशल और समग्र व्यक्तित्व को प्रदर्शित करने में आपकी मदद करेगा जो आपको बेहतर रोजगार के अवसर प्राप्त करने में मदद कर सकता है। */}
            </Text>
          </div>
        </div>


        <Stack align="center">
          <Group position="center" mt="md">
            <Button style={{ marginTop: "0px", color: "#1F7A8C" }} variant="filled"
              onClick={closePlay}>
              <Text c="white" size="md" mb={0} mt={0} fw={500}>
                <Trans>OK</Trans>
              </Text>
            </Button>
          </Group>
        </Stack>

      </Modal>


      {/* /////////////////////////// */}


      {loading ? (
        <div style={{ width: "100%", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Loader style={{ position: "relative", bottom: "100px" }} />
        </div>
      ) : (
        <div>
          {(myPlayShow == false) ? (
            <div>
              <Center>
                <Text style={{ fontWeight: "bold", fontSize: "20px", textAlign: "center" }}>
                  <Trans>Play</Trans>
                </Text>
              </Center>
              <div>

              </div>

              <div className={`${classes.wrapper} ${show ? classes.gridwrapperhide : classes.gridwrappershow}`}>
                {playvideos.map(videodata => {
                  return (
                    <Card style={{ height: "300px", width: "100%", borderRadius: "10px" }}>
                      {videodata?.videoStreamingUrl ? (
                        <>
                          <FaRegCirclePlay onClick={() => { modalOpen(videodata?.videoStreamingUrl, videodata) }} style={{ position: 'absolute', justifyContent: "center", alignItems: "center", marginTop: "100px", color: "#228BE6", alignSelf: "center", height: "20%", width: "20%", cursor: "pointer" }} size={26} />
                          <img src={videodata?.videoThumbnailUrl}
                            onClick={() => { modalOpen(videodata?.videoStreamingUrl) }} height="100%" width="100%" style={{ cursor: "pointer" }} />
                        </>

                      ) : (
                        <>
                          <FaRegCirclePlay onClick={() => { playVideo(videodata?.virtualPath, videodata) }} style={{ position: 'absolute', justifyContent: "center", alignItems: "center", marginTop: "100px", color: "#228BE6", alignSelf: "center", height: "20%", width: "20%", cursor: "pointer" }} size={26} />
                          <img src={videodata?.videoThumbnailUrl}
                            onClick={() => { playVideo(videodata?.virtualPath), videodata }} height="100%" width="100%" style={{ cursor: "pointer" }} />
                        </>
                      )}
                    </Card>
                  )
                })}
              </div>

              <div className={`${classes.videocontainers} ${show ? classes.videocontainerShows : classes.videocontainerHides}`}>
                <FaArrowLeft
                  style={{
                    color: "#fff",
                    cursor: "pointer",
                    fontSize: "30px",
                    position: "absolute",
                    left: "20px",
                    top: "40px",
                    zIndex: "1",

                  }}
                  onClick={() => {
                    setshow(!show);
                    setId(null); // Setting id to null
                  }}
                />

                {/* Only render ScrollContainer if id is not null */}
                {id && (
                  <ScrollContainer url={id} allvideos={playvideos} videoData={videoData} />
                )}
              </div>
            </div>
          ) : (
            <div>
              <Center>
                <Text style={{ fontWeight: "bold", fontSize: "20px", textAlign: "center" }}>
                  <Trans>My Play Videos</Trans>
                </Text>
              </Center>
              <div className={`${classes.wrapperMyVideo} ${show ? classes.gridwrapperhide : classes.gridwrappershow}`}>
                {myplayvideos.length > 0 ? (
                  <div>
                    {myplayvideos?.map(videodata => {
                      return (
                        <Card>
                          <Card style={{ height: "300px", width: "100%", borderRadius: "10px" }}>
                            {videodata?.approve == null && (
                              <div>
                                <Text style={{ textAlign: "right" }} c="blue">
                                  <Trans>Pending</Trans>
                                </Text>
                              </div>
                            )}
                            {videodata?.approve == false && (
                              <div>
                                <Text style={{ textAlign: "right" }} c="Red">
                                  <Trans>Rejected</Trans>
                                </Text>
                              </div>
                            )}
                            {videodata?.approve == true && (
                              <div>
                                <Text style={{ textAlign: "right" }} c="green">
                                  Approved
                                </Text>
                              </div>
                            )}
                            {videodata?.videoStreamingUrl ? (
                              <>
                                <FaRegCirclePlay onClick={() => { modalOpen(videodata?.videoStreamingUrl) }} style={{ position: 'absolute', justifyContent: "center", alignItems: "center", marginTop: "120px", color: "#228BE6", alignSelf: "center", height: "20%", width: "20%", cursor: "pointer" }} size={26} />
                                <img src={videodata?.videoThumbnailUrl} onClick={() => { modalOpen(videodata?.videoStreamingUrl) }} height="90%" width="100%" style={{ cursor: "pointer" }} />
                              </>

                            ) : (
                              <>
                                <FaRegCirclePlay onClick={() => { playVideo(videodata?.virtualPath) }} style={{ position: 'absolute', justifyContent: "center", alignItems: "center", marginTop: "120px", color: "#228BE6", alignSelf: "center", height: "20%", width: "20%", cursor: "pointer" }} size={26} />
                                <img src={videodata?.videoThumbnailUrl} onClick={() => { playVideo(videodata?.virtualPath) }} height="90%" width="100%" style={{ cursor: "pointer" }} />
                              </>
                            )}

                          </Card>

                          {(videodata?.approve == null || videodata?.approve == false) && (
                            <div>

                              {videodata?.candidateVideoFeedback?.videoPlayback == 0 ? (
                                <div>
                                  <Center>
                                    {videodata?.candidateVideoFeedback != null && (
                                      <Text style={{ justifyContent: "center", alignSelf: "center", fontWeight: "bold" }}>
                                        <Trans>Feedback Video</Trans>
                                      </Text>
                                    )}
                                  </Center>
                                  <Text>
                                    <Trans>The video file failed to open. Please try uploading the video again.</Trans>

                                  </Text>
                                </div>
                              ) : (
                                <div>
                                  {videodata?.candidateVideoFeedback?.videoDuration == 0 ? (
                                    <div>
                                      <Center>
                                        {videodata?.candidateVideoFeedback != null && (
                                          <Text style={{ justifyContent: "center", alignSelf: "center", fontWeight: "bold" }}>
                                            <Trans>Feedback Video</Trans>
                                          </Text>
                                        )}
                                      </Center>
                                      <Text>
                                        <Trans>Your video is too short. Aim for 10 to 100 seconds to cover your introduction, skills, and experience effectively.</Trans>
                                      </Text>
                                    </div>
                                  ) : (

                                    <div>
                                      {videodata?.candidateVideoFeedback?.videoDuration == 2 ? (
                                        <div>
                                          <Center>
                                            {videodata?.candidateVideoFeedback != null && (
                                              <Text style={{ justifyContent: "center", alignSelf: "center", fontWeight: "bold" }}>
                                                <Trans>Feedback Video</Trans>
                                              </Text>
                                            )}
                                          </Center>
                                          <Text>
                                            <Trans>Your video is too long. Aim for 10 to 100 seconds to cover your introduction, skills, and experience effectively.</Trans>
                                          </Text>
                                        </div>
                                      ) : (
                                        <div>
                                          {videodata?.candidateVideoFeedback?.faceDetection == 0 ? (
                                            <div>
                                              <Center>
                                                {videodata?.candidateVideoFeedback != null && (
                                                  <Text style={{ justifyContent: "center", alignSelf: "center", fontWeight: "bold" }}>
                                                    <Trans>Feedback Video</Trans>
                                                  </Text>
                                                )}
                                              </Center>
                                              <Text>
                                                <Trans>We couldn't detect your face in the video. For a better introduction, make sure your face is clearly visible throughout the video.</Trans>
                                              </Text>
                                            </div>
                                          ) : (
                                            <div>
                                              {videodata?.candidateVideoFeedback?.audio == 0 ? (
                                                <div>
                                                  <Center>
                                                    {videodata?.candidateVideoFeedback != null && (
                                                      <Text style={{ justifyContent: "center", alignSelf: "center", fontWeight: "bold" }}>
                                                        <Trans>Feedback Video</Trans>
                                                      </Text>
                                                    )}
                                                  </Center>
                                                  <Text>
                                                    <Trans>The audio seems to be missing from this video. Please upload a different one.</Trans>
                                                  </Text>
                                                </div>
                                              ) : (
                                                <div>
                                                  {videodata?.candidateVideoFeedback?.transcriptHindiText && (
                                                    <div>
                                                      {(languageOrigin == 'hi') ? (
                                                        <Text>
                                                          {videodata?.candidateVideoFeedback?.transcriptHindiText}
                                                        </Text>
                                                      ) : (
                                                        <Text>
                                                          {videodata?.candidateVideoFeedback?.transcriptEnglishText}
                                                        </Text>
                                                      )
                                                      }
                                                    </div>

                                                  )}
                                                </div>
                                              )
                                              }
                                            </div>)}
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          )}


                        </Card>

                      )
                    })}
                  </div>
                ) : (
                  <Card style={{ marginTop: "50px", paddingTop: "150px", paddingBottom: "150px" }}>
                    <Center>
                      <Text>
                        <Trans>No Video Available</Trans>
                      </Text>
                    </Center>
                  </Card>
                )}

                <div
                  style={{ marginTop: "60px" }}>
                </div>


              </div>

              <div className={`${classes.videocontainer} ${show ? classes.videocontainerShow : classes.videocontainerHide}`}>

                <FaArrowLeft style={{ color: "#fff", cursor: "pointer", fontSize: "20px", position: "absolute", left: "20px", top: "110px", zIndex: "1" }} onClick={() => {
                  setshow(!show)
                  setId(null)
                }} />
                <ReactPlayer url={id} controls={true} width="100%" height="100%" />
                {/* <VideoPlayer  url={id}/> */}

                {/* <VideoPlayer url="https://shraminmedia-inct.streaming.media.azure.net/11ab6b41-d6f5-498f-87b3-a049628b2c1d/Interview 5 Mistake final.ism/manifest(format=m3u8-cmaf)"/> */}


              </div>

              <Modal opened={opened} onClose={close} centered >
                <VideoPlayer url={`${id}`} controls={true} width="100%" height="500px" />
              </Modal>

            </div>)}

          <div style={{
            position: "fixed",
            width: "100%",
            height: "10%",
            // borderRadius: "30px",
            maxWidth: "600px",
            bottom: "63px",
            background: "#F6F6F6",
            padding: "10px",
            display: "grid",
            alignItems: "center",
            justifyContent: "'center",
            alignContent: "center",
          }}>

            <div>

              <div>

                <Center style={{ display: "flex", marginLeft: "20%", borderRadius: "30px", backgroundColor: "#228BE6", width: "50%", justifyContent: "space-between" }}>

                  <div onClick={openDisclaimer} style={{ paddingLeft: "20px", cursor: "pointer", marginRight: "5px", marginLeft: "10px", marginTop: "8px", marginBottom: "2px" }} >
                    <FiAlertCircle size={26} />
                  </div>

                  <div onClick={open4} style={{ marginRight: "5px", marginLeft: "5px", cursor: "pointer", marginTop: "6px", marginBottom: "2px", }} >
                    <FiCamera size={26} />
                  </div>

                  <div onClick={myPlayVideo} style={{ paddingRight: "20px", cursor: "pointer", marginRight: "10px", marginLeft: "5px", marginTop: "8px", marginBottom: "2px" }} >
                    {(myPlayShow == false) ? (<BsPersonCircle size={24} />) : (<GoRocket size={26} />)}
                  </div>

                </Center>

              </div>


            </div>

          </div>
        </div>
      )}

      {/* /////////////////////////// */}




    </div>

  )
}


export default Play