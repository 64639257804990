import React, { useState, useEffect } from 'react';
import classes from '../styles/Shraminshala.module.css';
import { useGetshraminshalavideosQuery } from '../redux/api/apiSlice';
import { useSelector } from 'react-redux';
import { Card, Text, Group } from '@mantine/core';
import logo from '../../public/shraminLogo.png';
import 'lite-youtube-embed';
import 'lite-youtube-embed/src/lite-yt-embed.css';
import { Trans } from '@lingui/macro';


const Shraminshala = () => {

  const [videos, setVideos] = useState([]);
  const [videoId, setVideoId] = useState(null); // Current video ID
  const token = useSelector((state) => state?.user.token);
  const { data: videoData, refetch } = useGetshraminshalavideosQuery({
    skip: !token,
  });

  useEffect(() => {
    if (token) {
      refetch();
    }
  }, [token, refetch]);

  useEffect(() => {
    if (videoData) {
      setVideos(videoData);
    }
  }, [videoData, videoId]);

  const handleVideoClick = (id) => {
    setVideoId((prevId) => (prevId === id ? null : id)); // Toggle videoId

  };



  return (
    <div className={classes.wrapper}>
      {videos.length > 0 && videos.map((video, i) => {
        const currentVideoId = video?.videoVirtualPath?.substring(32); // Ensure this is the correct ID
        return (
          <Card key={i} withBorder radius="md" p="md" mx={5} className={classes.card}>
            <Card.Section style={{ backgroundColor: 'black' }}>
              {videoId === currentVideoId ? (
                <lite-youtube
                  videoid={currentVideoId}
                />
              ) : (
                <div style={{ height: '100%', cursor: 'pointer' }}>

                  <lite-youtube
                    //  onClick={() => handleVideoClick(currentVideoId)} 
                    onMouseEnter={() => handleVideoClick(currentVideoId)}
                    onMouseLeave={() => handleVideoLeave(currentVideoId)}
                    onTouchStart={() => handleVideoClick(currentVideoId)}
                    onTouchEnd={() => handleVideoLeave(currentVideoId)}
                    videoid={currentVideoId}
                  />
                </div>
              )}
            </Card.Section>

            <Card.Section className={classes.section} mt="sm" p="4">
              <div>
                <img src={logo} height="45px" width="45px" alt="Logo" />
              </div>
              <div>
                <Group justify="apart">
                  <Text fz="sm" fw={500} mr={10}>
                    {video?.title}
                  </Text>
                </Group>
                <Text fz="sm" mt="xs" fw={500}>
                  <Trans>Posted Date -</Trans> {video?.createdAt?.split('-').reverse().join('/')}
                </Text>
              </div>
            </Card.Section>
          </Card>
        );
      })}
      <div style={{ marginTop: '80px' }}></div>
    </div>
  );
};


export default Shraminshala;