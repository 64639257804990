import React from 'react'
import {
  Card,
  Text,
  Image,
  Button,
  Center,
} from '@mantine/core';
import { IoArrowBack } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import { Trans } from '@lingui/macro';
import tutor from '../../../public/AITutoronboard.png' 
import { RxCross2 } from "react-icons/rx";

const TutorOnboard = () => {

  const navigate = useNavigate();
  const arrowBack = () => {
    navigate('/');
  }
  const gotonextscreen = () => {
    navigate('/tutorwheel');
  }


  return (
    <div>
      <div style={{
        position: "fixed",
        width: "100%",
        height: "8%",
        // borderRadius: "30px",
        maxWidth: "600px",
        top: "55px",
        // background: "white",
        padding: "0px",
        display: "grid",
        alignItems: "center",
        justifyContent: "'center",
        alignContent: "center",
        backgroundColor: "white"
      }}>



        <div>
          <IoArrowBack onClick={arrowBack} color='black' size={26} style={{ marginLeft: "10px", marginTop: "5px" }} />
        </div>
        <div style={{ marginTop: "-40px", marginLeft: "50px", marginRight: "50px", textAlign: "center", alignItems: "center", justifyContent: "center" }}>
          <Text style={{ fontWeight: "bold", fontSize: "20px", textAlign: "center" }}>
          <Trans>English Tutor</Trans>
          </Text>
        </div>
      </div>

      
      <div style={{marginTop: "100px", marginRight:"20px", margin:"20px"}}>
      {/* <div style={{ display: "flex", justifyContent: "flex-end", marginTop:"10px", marginBottom:"4px", paddingTop:"50px"  }}>
                        <RxCross2 
                        onClick={arrowBack} 
                        color='black' size={30}  />
                        </div> */}
   <Center style={{marginTop: "100px"}}>
      <Image src={tutor} onClick={gotonextscreen} style={{borderRadius:"10px", cursor:"pointer", marginTop:"20px", width:"50%", height:"40%"}}/>
      </Center>
      </div>

      <Center style={{marginTop: "0px", marginBottom: "10px"}}>
      <Text>
      <Trans>Learn English with Your Personal Tutor</Trans>
      </Text>
      </Center>

      <Button onClick={gotonextscreen} style={{width: "50%", marginLeft: "25%", marginTop:"10px"}} mb={"10px"} color='#0180ff' >
      <Trans>Get Started</Trans>
                    </Button>

         <div style={{marginTop: "60px"}}>
                 <h6 style={{color:'white'}}>.</h6>
                </div>   
        

      
    </div>
  )
}

export default TutorOnboard
