import React, { useState, useEffect } from 'react';
// import './App.css';
import { TextInput, Button, Text, Modal, Box, Group, Stack, Card, Title, Container, Code } from '@mantine/core';
import { IoArrowBack } from "react-icons/io5";
import { FaMicrophone } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';
import { t, Trans } from '@lingui/macro';
import tutor from '../../../public/tutor.png' 
import { RxCross2 } from "react-icons/rx";
import { LuRefreshCw } from "react-icons/lu";
import { UserAuth } from '../../context/AuthContext'
import { HiMiniSpeakerWave } from "react-icons/hi2";

const API_URL = import.meta.env.VITE_BASE_URL; // Update with your backend API URL

const SportAI = () => {

  const navigate = useNavigate();
  const { user } = UserAuth()
  const arrowBack = () => {
    navigate('/leanenglish');
  }
//   const gotonextscreen = () => {
//     navigate('/tutorwheel');
//   }


  const [previousQuestion, setPreviousQuestion] = useState("");
  const candidateId = localStorage.getItem('candidateId');
  const [openedOptionAlertEmpty, setOpenedOptionAlertEmpty] = useState(false);
  const [userResponse, setUserResponse] = useState('');
  const [aiFeedback, setAiFeedback] = useState("");
  const [correctedText, setCorrectedText] = useState("");
  const [correctedDetails, setCorrectedDetails] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isNext, setIsNext] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isListening, setIsListening] = useState(false);
  const [isListeningtill, setIsListeningtill] = useState(true);

  const [timer, setTimer] = useState(0);  // Track the timer for the loading bar

  const closeOptionAlertEmpty = () => {
    setOpenedOptionAlertEmpty(false);
  };

  // const handleButtonClick = () => {
  //   // Scroll to the top of the page
  //   window.scrollTo(0, 0);
  // };

  useEffect(() => {
    getQuestion(); // Only runs once when the component mounts
  }, [user]); // Ensure this dependency array is empty

  const getQuestion = async () => {
    window.scrollTo(0, 0);
    setIsLoading(true);
    setAiFeedback("");
    setUserResponse('');
    setIsSubmitted(false);
    setIsNext(false);
    setCorrectedText("");
    setCorrectedDetails("");
    setIsListeningtill(true);

    try {
      const response = await fetch(`${API_URL}/generate-sports-question/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${user?.accessToken}`,
          "Accept": 'application/json'
        },
      });
      const data = await response.json();
     // console.log("ssssssssssshhhhhhhhh", data)
      if (data) {
        const newText = data?.question; // This is the message from the API
        //setCorrectedText(newText);
        // Ensure the variable is up-to-date before passing to textToSpeech
        textToSpeech(newText);
      }
    
      const question = data.question || "No question generated.";
      setPreviousQuestion(question); // This sets the new question
    } catch (error) {
      console.error("Error retrieving question:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const textToSpeech = async (text) => {
    try {
      const response = await fetch(`${API_URL}/text-to-speech/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${user?.accessToken}`,
          // You might want to remove the "Accept" header or change it based on the server's requirements
          // "Accept": 'application/json' // Modify or remove this if not required
        },
        body: JSON.stringify({ text }),
      });
  
      // Check if the response is valid
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      // Assuming the response is an audio blob (e.g., mp3, wav)
      const audioBlob = await response.blob();
      const audioUrl = URL.createObjectURL(audioBlob);
      new Audio(audioUrl).play();
    } catch (error) {
      console.error("Error retrieving audio:", error);
    }
  };
  


  // const clearDictation = () => {
  //  // console.log("aaaaaaaakkkkkkkkkk")
  //   setUserResponse(""); 
  //   setIsSubmitted(false);
  //   setIsNext(false);
  // }


  // const preStartDictation = () => {
  //   setUserResponse('');
   
  //   if(userResponse == ''){
  //     startDictation();
  //   }

  // }


  const startDictation = () => {
    const recognition = new (window.SpeechRecognition || window.webkitSpeechRecognition)();
    recognition.lang = 'en-US';
    recognition.interimResults = false;
    recognition.maxAlternatives = 1;

    setIsListening(true);

    recognition.start();

    recognition.onresult = (event) => {
      const transcript = event.results[0][0].transcript;
      setUserResponse(transcript);
      setIsListening(false);
      setIsListeningtill(false);
    };

    recognition.onerror = (event) => {
      console.error("Speech recognition error:", event.error);
      setIsListening(false);
    };

    recognition.onend = () => {
      setIsListening(false); // Reset when recognition ends naturally
    };
  };
  
  
  

  const submitAnswer = async () => {
    if (userResponse) {
      setIsLoading(true);
      try {
        const response = await fetch(`${API_URL}/sports/check-answer/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${user?.accessToken}`,
            "Accept": 'application/json'
          },
          body: JSON.stringify({ 
              question: previousQuestion,
              answer: userResponse,
              candidateId : candidateId
          }),
        });
        const correctedData = await response.json();

        // Check if the API response contains the expected fields
        if (correctedData && correctedData.message) {
          const newText = correctedData.message; // This is the message from the API
          const newTextDetail = correctedData.details || '';
          const messageCode = correctedData?.message_code;
          let newDetailsOne = correctedData.details || ''; // This is the details from the API

          newDetailsOne = newDetailsOne.replace(/[^a-zA-Z0-9\s]/g, '').trim();

          const newDetails = newDetailsOne;

          // Set the feedback state with the received data
          setCorrectedText(newText);
          setCorrectedDetails(newDetails);
          textToSpeech(`${newText} ${newDetails}`);
          setAiFeedback(
            messageCode === '0' ? (
              <Container style={{ padding: '20px'}}>
                <Card shadow="sm" padding="lg" radius="md" withBorder style={{backgroundColor:"#febcbc"}}>
                  <Title align="center" style={{ marginBottom: '10px', fontSize: '16px' }}>
                    {newText}
                  </Title>
                  <Text color="#333433" align="left" style={{ fontSize: '16px' }}>
                    {newTextDetail}
                  </Text>
                </Card>
              </Container>
            ) : (
              <Container style={{ padding: '20px'}}>
                <Card shadow="sm" padding="lg" radius="md" withBorder style={{backgroundColor:"#bffebc"}}>
                  <Title align="center" style={{ marginBottom: '10px', fontSize: '16px' }}>
                    {newText}
                  </Title>
                  <Text color="#333433" align="left" style={{ fontSize: '16px' }}>
                    {newTextDetail}
                  </Text>
                </Card>
              </Container>
            ) 
          );
          setIsSubmitted(true);
          setIsNext(true);
        } else {
          console.error("Invalid response format from AI tutor.");
        }
      } catch (error) {
        console.error("Error in getting corrected text:", error);
      } finally {
        setIsLoading(false);
      }
    } else {
      //alert("Please enter some text.");
      setOpenedOptionAlertEmpty(true);
    }
  };




  return (
    <div>
      <div style={{
        position: "fixed",
        width: "100%",
        height: "8%",
        // borderRadius: "30px",
        maxWidth: "600px",
        top: "55px",
        // background: "white",
        padding: "0px",
        display: "grid",
        alignItems: "center",
        justifyContent: "'center",
        alignContent: "center",
        backgroundColor: "white"
      }}>



        <div>
          <IoArrowBack onClick={arrowBack} color='black' size={26} style={{ marginLeft: "10px", marginTop: "5px" }} />
        </div>
        <div style={{ marginTop: "-40px", marginLeft: "50px", marginRight: "50px", textAlign: "center", alignItems: "center", justifyContent: "center" }}>
          <Text style={{ fontWeight: "bold", fontSize: "20px", textAlign: "center" }}>
          <Trans>English Tutor</Trans>
          </Text>
        </div>
      </div>

      <Modal
  withCloseButton={false}
  opened={openedOptionAlertEmpty}
  onClose={() => setOpenedOptionAlertEmpty(false)}
  centered
>
  <div style={{ display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center" }}>
    <Text fw={500} style={{ color: "#1F7A8C", fontSize: "1rem", marginBottom: "1rem" }}>
    <Trans>Please speak your answer</Trans>
    </Text>

    <Stack align="center">
      <Group position="center" mt="md">
        <Button style={{ borderColor: "#1F7A8C" }} variant="outline" onClick={closeOptionAlertEmpty}>
          <Text c="#1F7A8C" size="sm" fw={500}>
            <Trans>OK</Trans>
          </Text>
        </Button>
      </Group>
    </Stack>
  </div>
</Modal>

      
  

<div style={{ textAlign: 'center', marginRight:"20px", marginLeft: '20px' }}>

      <h3 style={{ fontSize: '17px', fontWeight:"lighter", marginTop: "110px", paddingTop:"10px" }}><Trans>Sports</Trans></h3>
      {isLoading ? (
        <div className="loading-bar">Loading...</div>
      ) : (
        <>
        {/* <h2 style={{ fontSize: '15px', fontWeight:"lighter" }}>Let's start learning English!</h2> */}
        <h3 style={{ fontSize: '17px',marginTop:"-10px", fontWeight:"normal" }}>{previousQuestion}</h3>
        </>
      )}


<div style={{ marginTop: "10px" }}>

{isListening || isSubmitted ? (
  <div
    //onClick={preStartDictation}
    style={{
      display: "inline-flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100px",
      height: "100px",
      borderRadius: "50%",
      backgroundColor: isListening ? "#ffccb3 " : "#f5f5f5",
     // backgroundColor: "#e0f7fa", // Light blue background for contrast
      cursor: "pointer",
      marginBottom: "15px",
    }}
  >
    <FaMicrophone size={40} style={{color: isListening ? "red" : "grey"}} />
  </div>
) : (
<div
    onClick={startDictation}
    style={{
      display: "inline-flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100px",
      height: "100px",
      borderRadius: "50%",
      backgroundColor: "#e0f7fa",
     // backgroundColor: "#e0f7fa", // Light blue background for contrast
      cursor: "pointer",
      marginBottom: "15px",
    }}
  >
    <FaMicrophone size={40} style={{color: "blue"}} />
  </div>
)}
      <div style={{marginBottom:"10px"}}>
      {isListening ? <Trans>We are listening....</Trans> : <Trans>Click on the microphone button</Trans>}
      </div>

      {/* Loading Bar */}
    {/*  {isListening && (
        <div style={{ width: '100%', height: '5px', backgroundColor: '#ccc', marginBottom:"20px", marginTop: '10px' }}>
          <div
            style={{
              width: `${(timer / 15) * 100}%`,  // Calculate the width based on the timer
              height: '100%',
              backgroundColor: '#4caf50',
              transition: 'width 1s linear',  // Smooth transition effect
            }}
          />
        </div>
      )}*/}

  {/* <div
    onClick={startDictation}
    style={{
      display: "inline-flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100px",
      height: "100px",
      borderRadius: "50%",
      backgroundColor: isListening ? "#8ff97a" : "#e0f7fa",
     // backgroundColor: "#e0f7fa", // Light blue background for contrast
      cursor: "pointer",
      marginBottom: "30px",
    }}
  >
    <FaMicrophone size={40} color="blue" />
  </div> */}

  <div style={{ display: "flex", alignItems: "center", marginTop:"10px" }}>
      <textarea
      placeholder={t`To respond, please click on the microphone button above.`}
        value={userResponse}
        onChange={(e) => setUserResponse(e.target.value)}
        rows={2}
        disabled // Makes the textarea disabled
        style={{
          width: "100%",
          borderRadius: "8px",
          padding: "10px",
          fontSize: "16px",
          border: "1px solid #ccc",
          backgroundColor: "#f5f5f5", // Light background for disabled effect
          color: "#999", // Text color for disabled effect
          cursor: "not-allowed", // Shows disabled cursor
          marginRight: "0px", // Spacing between textarea and icon
        }}
      />

      {/* <div
        onClick={clearDictation}
        style={{
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <LuRefreshCw size={20} color="black" />
      </div> */}

    </div>



  </div>

    <Box style={{ display: 'flex', marginTop:"20px", justifyContent: 'center', gap: '1rem' }}>
      {/* {isListeningtill ? (
         <Button style={{ backgroundColor: isSubmitted ? 'gray' : 'gray',}}
          //onClick={submitAnswer}
          disabled={isSubmitted || isLoading} radius="md" size="md" color="violet"><Trans>Submit Answer</Trans>
          </Button>
      ) : ( */}
        <Button style={{ backgroundColor: isSubmitted ? 'gray' : 'blue', fontSize:"14px"}}
        onClick={submitAnswer}
        disabled={isSubmitted || isLoading} radius="md" color="violet"><Trans>Submit Answer</Trans>
        </Button>
      {/* )} */}
         
          <Button style={{fontSize:"14px"}}
           onClick={getQuestion}
           disabled={!isSubmitted || isLoading} 
           radius="md" variant="outline"><Trans>Next Question</Trans></Button>
        </Box>  

   

      {isLoading && (
        <div className="circular-loading" style={{ marginTop: '10px' }}>
          <div className="spinner"></div>
        </div>
      )}

      {aiFeedback && (
        <div style={{ marginTop: '5px' }}>
          {aiFeedback}
          {/* <div
        onClick={() => textToSpeech(`${correctedText}. ${correctedDetails}`)}
        style={{
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <HiMiniSpeakerWave size={20} color="black" />
      </div> */}
      <div>
      <Text color="#333433" align="left" style={{ fontSize: '12px', marginTop: '5px' }}>
      <Trans>The AI English Tutor is a great tool for basic language learning, providing helpful guidance, though it may not always be correct.</Trans>
                  </Text>
      </div>
        </div>
      )}

      
    </div>

         <div style={{marginTop: "60px"}}>
                 <h6 style={{color:'white'}}>.</h6>
                </div>   
        

      
    </div>
  )
}

export default SportAI