import { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import ReactPlayer from "react-player/lazy";
import './Scroller.css';
import VideoPlayerPlay from "../VideoPlayerplay.jsx";
import LikeShareButtons from "./LikeShareButton.jsx";

function ScrollContainer({ url, allvideos, videoData }) {
  //console.log("llllllllll", allvideos)
  const [activeVideoIndex, setActiveVideoIndex] = useState(null);
  const containerS = {
    position: 'relative',
    top: '0%',
    right: '35%',
    transform: 'translateY(-50%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '15px',
    borderRadius: '0px',
    zIndex: 1020,
  };

  return (
    <div>
      <div className="list">
        <VideoPlayer
          url={url}
          activeVideoIndex={activeVideoIndex}
          setActiveVideoIndex={setActiveVideoIndex}
        />
        <div style={containerS}>
          <LikeShareButtons disLikesTotal={videoData?.disliked} likesTotal={videoData?.liked} candidateVideoId={videoData?.id} candidateName={videoData?.candidateName} candidateLikeVideo={videoData?.likedCandidates} candidateDislikeVideo={videoData?.dislikedCandidates} />
        </div>
        {allvideos.map((el, i) => (
          <div>
            <VideoPlayer
              key={el.id}

              url={el.videoStreamingUrl ? (el.videoStreamingUrl) : el.virtualPath
              }
              boolean={el.videoStreamingUrl ? true : false}
              index={i}
              activeVideoIndex={activeVideoIndex}
              setActiveVideoIndex={setActiveVideoIndex}
            />


            <div style={containerS}>
              <LikeShareButtons likesTotal={el.liked} disLikesTotal={el?.disliked} candidateId={el.candidate_id} candidateVideoId={el.id} candidateName={el.candidateName} candidateLikeVideo={el?.likedCandidates} candidateDislikeVideo={el?.dislikedCandidates} />
            </div>


          </div>
        ))}
      </div>
    </div>
  );
}

function VideoPlayer({ url, index, activeVideoIndex, setActiveVideoIndex, boolean }) {
  const [ref, isVisible] = useInView({
    threshold: 0.6,
  });

  useEffect(() => {
    if (isVisible) {
      setActiveVideoIndex(index);
    }
  }, [isVisible, index, setActiveVideoIndex]);



  return (
    <>
      <div className="item" ref={ref}>
        {boolean ? (
          <VideoPlayerPlay
            width="100%"
            url={url}
            index={index}
            activeVideoIndex={activeVideoIndex}
          />
        ) : (
          <ReactPlayer
            url={url}
            width="100%"
            height="79vh"
            playing={activeVideoIndex === index} // Only play when this video is active
            controls
            muted={activeVideoIndex !== index}
          />
        )}
      </div>
    </>
  );
}

export default ScrollContainer;
