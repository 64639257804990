import React, { useState, useRef } from 'react';
import {
  Card,
  Text,
  Box,
  Button,
  Center,
} from '@mantine/core';
import { IoArrowBack } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import { Trans } from '@lingui/macro';
import { useMove } from '@mantine/hooks';
import './TutorWheel.css';

const TutorWheel = () => {

  const navigate = useNavigate();
  const arrowBack = () => {
    navigate('/tutoronboard');
  }

  const gotonextscreen = () => {
    navigate('/leanenglish');
  }

  const wheelRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startAngle, setStartAngle] = useState(0);
  const [currentAngle, setCurrentAngle] = useState(0);
  
  const getAngle = (x, y) => {
    const rect = wheelRef.current.getBoundingClientRect();
    const wheelCenterX = rect.left + rect.width / 2;
    const wheelCenterY = rect.top + rect.height / 2;
    const deltaX = x - wheelCenterX;
    const deltaY = y - wheelCenterY;
    return Math.atan2(deltaY, deltaX) * (180 / Math.PI);
  };
  
  const handleStart = (e) => {
    e.preventDefault();
    setIsDragging(true);
    const angle = getAngle(e.touches ? e.touches[0].clientX : e.clientX,
                            e.touches ? e.touches[0].clientY : e.clientY);
    setStartAngle(angle - currentAngle); // Capture the difference
  };
  
  const handleMove = (e) => {
    if (!isDragging) return;
  
    const angle = getAngle(e.touches ? e.touches[0].clientX : e.clientX,
                            e.touches ? e.touches[0].clientY : e.clientY);
    const newAngle = angle - startAngle; // Apply the difference
    setCurrentAngle(newAngle); // Update current angle
    wheelRef.current.style.transform = `rotate(${newAngle}deg)`; // Use newAngle
  };
  
  const handleEnd = () => {
    setIsDragging(false);
  };
  
  const sections = [
    { name: "Interview Preparation", angle: 120 },
    { name: "Learn English", angle: 0 },
    { name: "Exam Preparation", angle: 240 },
  ];

  // const getSelectedSection = () => {
  //   const normalizedAngle = (currentAngle % 360 + 360) % 360; // Normalize angle between 0 and 360
  //   const sectionIndex = Math.round(normalizedAngle / (360 / sections.length)) % sections.length;
  //   return sections[sectionIndex];
  // };

  // const selectedSection = getSelectedSection();

  const getSelectedSection = () => {
    const topOffset = 178; // Adjust offset to set "top" as the selected position
    const adjustedAngle = (currentAngle + topOffset) % 360;
    const normalizedAngle = (adjustedAngle + 360) % 360; // Normalize angle between 0 and 360
    const sectionIndex = Math.round(normalizedAngle / (360 / sections.length)) % sections.length;
    return sections[sectionIndex];
  };
  
  const selectedSection = getSelectedSection();
  
  return (
    <div>
      <div style={{
        position: "fixed",
        width: "100%",
        height: "8%",
        maxWidth: "600px",
        top: "55px",
        padding: "0px",
        display: "grid",
        alignItems: "center",
        justifyContent: "'center",
        alignContent: "center",
        backgroundColor: "white"
      }}>
        <div>
          <IoArrowBack onClick={arrowBack} color='black' size={26} style={{ marginLeft: "10px", marginTop: "5px" }} />
        </div>
        <div style={{ marginTop: "-40px", marginLeft: "50px", marginRight: "50px", textAlign: "center", alignItems: "center", justifyContent: "center" }}>
          <Text style={{ fontWeight: "bold", fontSize: "20px", textAlign: "center" }}>
          <Trans>English Tutor</Trans>
          </Text>
        </div>
      </div>

      <Center style={{ paddingTop: "70px" }}>
        <Text style={{ fontWeight: "500", fontSize: "18px", textAlign: "center" }}>
        <Trans>What do you want to learn today?</Trans>
        </Text>
      </Center>

      <Center style={{ marginTop: "20px" }}>
        <Text style={{ fontWeight: "500", fontSize: "18px", textAlign: "center", color:"#228be6" }}>
         {selectedSection.name}
        </Text>
      </Center>

      {/* <div
    className="arrow"
    style={{
      transform: `translate(-50%, -100%) rotate(${180}deg)`, // Rotate based on section angle
    }}
  /> */}

<div
        className="arrow"
        style={{
          position: 'absolute',
          top: '25.5%', // Position the arrow near the top of the circle
          left: '50%',
          transform: 'translateX(-50%) rotate(360deg)', // Adjust rotation and centering
        }}
      />
   

      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '45vh' }}>
 
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      backgroundColor: '#00bfa5',
    }}
    onMouseMove={handleMove}
    onMouseUp={handleEnd}
    onMouseLeave={handleEnd}
    onTouchMove={handleMove}
    onTouchEnd={handleEnd}
    onTouchStart={handleStart}
  >
    <div
      ref={wheelRef}
      className="wheel"
      onMouseDown={handleStart}
      onTouchStart={handleStart}
      style={{ transform: `rotate(${currentAngle}deg)` }}
    >
     {sections.map((section, index) => {
  const sectionAngle = section.angle;
  const x = 70 * Math.cos((sectionAngle * Math.PI) / 285);
  const y = 78 * Math.sin((sectionAngle * Math.PI) / 235);
  const [line1, line2] = section.name.split(' ');

        return (
          <Text
          key={index}
          style={{
            position: 'absolute',
            top: '42%',
            left: '44%',
            right: '40%',
            bottom: '37%',
            transform: `translate(${x}px, ${y}px) translate(-50%, -50%) rotate(${-currentAngle}deg)`,
            cursor: 'pointer',
            color: 'black',
            fontWeight: 'normal',
            userSelect: 'none',
            textAlign: 'center',
            fontSize: '0.8rem',
            '@media (max-width: 600px)': {
              fontSize: '0.8rem',
            },
          }}
        >
          {line1}<br />{line2}
        </Text>
        );
      })}
    </div>
  </Box>
</div>



<Center style={{ marginTop: "10px" }}>
        {selectedSection.name === "Learn English" ? (
          <Button onClick={gotonextscreen} style={{width: "40%", marginTop:"10px"}} mb={"10px"} color='#0180ff' >
          <Trans>Next</Trans> 
                        </Button>
        ) : (
          <Text style={{ fontSize: "20px", color: "gray" }}><Trans>Coming Soon</Trans></Text>
        )}
      </Center>

      
    </div>
  );
}

export default TutorWheel;
