import React, { useRef, useEffect } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

const VideoPlayerPlay = ({ url, index, activeVideoIndex }) => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);

  useEffect(() => {
    if (!playerRef.current) {

      // Initialize video.js player
      playerRef.current = videojs(videoRef.current, {
        controls: true,
      });


      playerRef.current.src({
        src: url,
        type: 'application/x-mpegURL'
      });


      playerRef.current.on('error', (event) => {
        console.error('Video.js error:', event);
      });
    } else {

      // Update video source if URL changes
      playerRef.current.src({
        src: url,
        type: 'application/x-mpegURL'
      });
    }
    if (playerRef.current) {
      if (index === activeVideoIndex) {
        playerRef.current.play();
      } else {
        playerRef.current.pause();
      }
    }
  }, [url, activeVideoIndex]);


  return (
    <div data-vjs-player fluid p={0} style={{ width: '100%', height: '78vh', backgroundColor: '#eee' }} >
      <video
        id={`my_video_${index}`}
        className="video-js"
        autoPlay={false}
        ref={videoRef}
        controls
        style={{
          width: '100%',
          height: '79vh',
          objectFit: 'none',
        }}
      />
    </div>
  );
};

export default VideoPlayerPlay;
