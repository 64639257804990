import {
  Card,
  Text,
  SimpleGrid,
  UnstyledButton,
  useMantineTheme,
  Button,
  Badge,
  Grid
} from '@mantine/core';

import searchlogo from '../assets/logos/Search.png'
import govtjoblogo from '../assets/logos/Home.png'
import personlogo from '../assets/logos/Person.png'
import ActivePlayLogo from '../assets/logos/play.png'
import ActiveCareerLogo from '../assets/logos/career.png'
import courselogo from '../assets/logos/courses.png'
import alphabetlogo from '../assets/logos/abc.png'
import { IoBookOutline } from "react-icons/io5";

import { useNavigate } from 'react-router-dom'
import classes from '../styles/ActionHomeSection.module.css';
import { Trans } from '@lingui/macro';
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { defaultLocale } from '../../i18n';
import { loadCatalog } from '../../i18n'
import NotificationPermission from "./NotificationPermission.jsx";

import { IoMdDownload } from "react-icons/io";
import { PiWhatsappLogoLight } from "react-icons/pi";
import CustomModalInApp from '../components/CustomInstallModalInApp'
import { FaTelegram } from "react-icons/fa6";
import { FaShareAlt } from "react-icons/fa";
import { BsChatDots } from "react-icons/bs";
import { RiGraduationCapLine } from "react-icons/ri";




//i18n.activate(loadCatalog);

const mockdata = [
  { title: <Trans>Jobs</Trans>, color: 'violet', icon: searchlogo, link: "/jobs?tab=all" },
  { title: <Trans>Govt. Jobs</Trans>, color: 'indigo', icon: govtjoblogo, link: "/jobs?tab=govt" },
  { title: <Trans>Career</Trans>, color: 'blue', icon: ActiveCareerLogo, link: "/shraminshala" },
  { title: <Trans>English Tutor</Trans>, color: 'green', icon: alphabetlogo, link: "/tutorOnboard" },
  // { title: <Trans>Play</Trans> ,  color: 'teal' , icon : ActivePlayLogo , link : "/play" },
  // { title: <Trans>Profile</Trans>,  color: 'cyan' , icon : personlogo , link:"/myprofile" },
];

function ActionsGrid() {
  const theme = useMantineTheme();
  const navigate = useNavigate();


  const handleRedirect = (link) => {
    navigate(link)
  }

  const sharewhatsappbtn = () => {
    window.open("https://whatsapp.com/channel/0029VaAYCuQEawdmh2V3fH2q")
  }

  const sharetelegrambtn = () => {
    window.open("https://t.me/shraminjobs")
  }

  const handleRedirectbtn = () => {
    navigate(`/chatbot`)
  }

  const handleCourseRedirectbtn = () => {
    navigate(`/courses`)
  }

  const shareallbtn = () => {
    //console.log("abcd") 
    if (navigator.share) {
      navigator.share({
        title: 'ShramIN App',
        text: `SharmIN Jobs App` + `${"\n"}` + `India's best job search app for skilled technicians` + `${"\n"}`,
        url: 'https://www.shramin.app/'
      }).then(() => {
        // console.log('Thanks for sharing!');
      }).catch(err => {
        //console.log("Error while using Web share API:");
        console.log(err);
      });
    } else {
      alert("Browser doesn't support this API !");
    }
  };


  // const items = mockdata.map((item) => (
  //   <UnstyledButton style={{ marginLeft: "3px", marginRight: "3px", boxShadow: "rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px" }} key={item.title} className={classes.item} onClick={() => { handleRedirect(item.link) }}>
  //     <img src={item.icon} style={{ height: "30px", width: "30px" }} />
  //     <Text mt={7} color='#0180ff' style={{ textAlign: "center", fontSize: "14px" }}>
  //       {item.title}
  //     </Text>
  //     {item === 4 && (
  //             <Badge style={{ marginTop: '-15px' }} variant="light" color="blue" size="sm" radius="sm">
  //               New
  //             </Badge>
  //           )}
  //   </UnstyledButton>

  // ));

  const items = mockdata.map((item, index) => (
    <UnstyledButton
      style={{
        marginLeft: "3px",
        marginRight: "3px",
        boxShadow: "rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px",
        position: 'relative', // Make the container relative for absolute positioning of the badge
      }}
      key={item.title}
      className={classes.item}
      onClick={() => {
        handleRedirect(item.link);
      }}
    >
      <img src={item.icon} style={{ height: "30px", width: "30px" }} />
      <Text mt={7} color="#0180ff" style={{ textAlign: "center", fontSize: "14px" }}>
        {item.title}
      </Text>
  
      {/* Show "New" badge only on the last item and position it at the top-right */}
      {index === mockdata.length - 1 && (
        <Badge
          style={{
            position: 'absolute',
            top: '-5px', // Adjust the distance from the top
            right: '-5px', // Adjust the distance from the right
            zIndex: 10, // Ensure the badge is on top
          }}
          //variant="light"
          color="red"
          size="sm"
          radius="xs"
        >
          New
        </Badge>
      )}
    </UnstyledButton>
  ));
  

  return (
    <div style={{ margin: "2.3%", backgroundColor: "#fff", borderWidth: "0.5", borderColor: "#D2D2D2" }}>
      <Card padding="lg" style={{ flexDirection: "row", padding: "2%", }}>
        {items}
      </Card>



      <div style={{ borderRadius: "10px" }}>
        <Grid style={{ margin: "2.6%", paddingBottom: "3.8%" }}>
          <Grid.Col style={{ backgroundColor: "white", borderRadius: "20px", boxShadow: "rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px", margin: "0.61%" }} span={2.81}>
            <Button onClick={sharewhatsappbtn} style={{ width: '100%', backgroundColor: "white" }}>
              <PiWhatsappLogoLight
                style={{ fontSize: "30px", color: "green", fontWeight: "300" }}
                cursor="pointer"
                onClick={sharewhatsappbtn}
              />
            </Button>
            <Text onClick={sharewhatsappbtn} style={{ textAlign: "center", cursor: "pointer", marginTop: "4px", fontSize: "11px" }}><Trans>Join WhatsApp Channel</Trans></Text>
          </Grid.Col>

          <Grid.Col style={{ backgroundColor: "white", borderRadius: "20px", boxShadow: "rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px", margin: "0.61%" }} span={2.81}>
            <Button onClick={sharetelegrambtn} style={{ width: '100%', backgroundColor: "white" }}>
              <FaTelegram
                style={{ fontSize: "27px", color: "#0190ff", fontWeight: "300" }}
                cursor="pointer"
                onClick={sharetelegrambtn}
              />
            </Button>
            <Text onClick={sharetelegrambtn} style={{ textAlign: "center", cursor: "pointer", marginTop: "4px", fontSize: "11px" }}><Trans>Join Telegram Channel</Trans></Text>
          </Grid.Col>

          <Grid.Col style={{ backgroundColor: "white", borderRadius: "20px", boxShadow: "rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px", margin: "0.61%" }} span={2.81}>
            <Button onClick={handleRedirectbtn} style={{ width: '100%', backgroundColor: "white" }}>
              <BsChatDots
                style={{ fontSize: "28px", color: "#a656de", fontWeight: "300" }}
                cursor="pointer"
                onClick={handleRedirectbtn}
              />
            </Button>
            <Text onClick={handleRedirectbtn} style={{ textAlign: "center", cursor: "pointer", marginTop: "4px", fontSize: "11px" }}><Trans>Career Guidance</Trans></Text>
          </Grid.Col>

          <Grid.Col style={{ backgroundColor: "white", borderRadius: "20px", boxShadow: "rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px", margin: "0.61%" }} span={2.81}>
            <Button onClick={handleCourseRedirectbtn} style={{ width: '100%', backgroundColor: "white" }}>
              <IoBookOutline
                style={{ fontSize: "28px", color: "#0190ff", fontWeight: "300" }}
                cursor="pointer"
                onClick={handleCourseRedirectbtn}
              />
            </Button>
            <Text onClick={handleCourseRedirectbtn} style={{ textAlign: "center", cursor: "pointer", marginTop: "4px", fontSize: "12px" }}><Trans>Courses</Trans></Text>
          </Grid.Col>

        </Grid>

      </div>
    </div>

  );
}


export default ActionsGrid