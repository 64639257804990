import React from 'react'
import {
    Card,
    Text,
    Image,
    SimpleGrid,
    Button,
    UnstyledButton,
    Anchor,
    Paper,
    Group,
    useMantineTheme,
    Center,
    Combobox,
    Container,
    Flex,
    Input,
    InputBase,
    useCombobox
  } from '@mantine/core';
  import classes from '../styles/JobLocationSection.module.css'
  import { FaPen } from "react-icons/fa";
import { apiSlice, useActiveLocationCategoryMutation, useGetStateDetailsQuery, useGetActiveLocationDetailsQuery } from '../redux/api/apiSlice';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useEditProfileDetailsMutation } from '../redux/api/apiSlice'
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { IoArrowBack } from "react-icons/io5";



const JobLocation = () => {
  const { i18n } = useLingui();
  
  var candidateId = localStorage.getItem('candidateId');
 // console.log("candidate Id", candidateId)

    const navigate = useNavigate()
    const [editProfile] = useEditProfileDetailsMutation()
    const [allowShow, setAllowShow] = useState(false);



    const token = useSelector((state) => state?.user?.token)
    const [locationValue, setLocationValue] = useState(null);

    const [searchHeight, setSearchHeight] = useState(false)
    const combobox = useCombobox({
      onDropdownClose: () => combobox.resetSelectedOption(),
    });
  
    const [value, setValue] = useState(null);
    const [search, setSearch] = useState('');



    const theme = useMantineTheme();

    const { data: locationData, isLoading: locationLoading, isError: locationError, isFetching: locationFetching, refetch: locationRefetch } = useGetActiveLocationDetailsQuery({
        skip: !token
    })

    const { data: stateData, isLoading: stateLoading, isError: stateError, isFetching: stateFetching, refetch: stateRefetch } = useGetStateDetailsQuery({
      skip: !token
  })

    const [jobLocationCategory] = useActiveLocationCategoryMutation()

    useEffect(() => {
        if (token) {
            locationRefetch()
            stateRefetch()
        }
    }, [token, locationRefetch, stateRefetch]);

    const openSearch = () => {
      setSearchHeight(true)
      combobox.openDropdown()
    }

    const closeSearch = () => {
      combobox.closeDropdown()
      if(value == null){
        setSearchHeight(false)
      }
    }

    /////////////////////

    const handleSuccess = (position) => {
      const { latitude, longitude } = position.coords;
     // console.log("latitudelongitude",latitude, longitude)
     
      editProfile({
          latitude: latitude,
          longitude: longitude
      })
      navigate('/createprofileonboarding');
  };

  const handleError = (error) => {
    
    navigate('/createprofileonboarding');
    //  setAllowShow(true)
      
  };

  const allowJobLocationFun = () => {
    if (!navigator.geolocation) {
      console.log('Geolocation is not supported by your browser');
      navigator.geolocation.getCurrentPosition(handleSuccess, handleError);
  } else {
      navigator.geolocation.getCurrentPosition(handleSuccess, handleError);
  }
  };

  const notAllowJobLocationFun = () => {
    navigate('/createprofileonboarding');
   // setAllowShow(true)
  };

  // useEffect(() => {
  //     if (!navigator.geolocation) {
  //         console.log('Geolocation is not supported by your browser');
  //     } else {
  //         navigator.geolocation.getCurrentPosition(handleSuccess, handleError);
  //     }
  // }, []);



    ////////////////////


    const handleSubmit = (item) => {
      //console.log("asssssssswwwwwww", item?.id, candidateId)
     // console.log(JSON.stringify(values) + "before convert")
      const convertedObject = {
          
          Id: parseInt(candidateId),
          selected_location_id: parseInt(item?.id),
          
      };
      console.log(JSON.stringify(convertedObject) + "after convert");
      jobLocationCategory(convertedObject);
      navigate('/createprofileonboarding');
  }

  const handleSubmit2 = (item) => {
    //console.log("asssssssswwwwwww", item?.id, candidateId)
   // console.log(JSON.stringify(values) + "before convert")
    const convertedObject = {
        
        Id: parseInt(candidateId),
        selected_location_id: parseInt(item?.id),
        
    };
    console.log(JSON.stringify(convertedObject) + "after convert");
    jobLocationCategory(convertedObject);
    navigate('/createprofileonboarding');
}

const arrowBack = () => {
  navigate('/onboardingbasicinformation');
}

  
   // style={{backgroundColor: '#F6F6F6'}}

  const items = locationData?.map((item) => (
    <UnstyledButton onClick={()=>{handleSubmit(item)}} key={item.masterName} style={{backgroundColor: '#F6F6F6'}} className={classes.item}>
   <Image style={{width: 45,
    height: 45,}}
              src={item.logoVirtualPath}/>
              <Text style={{textAlign: "center"}}
               size="xs" mt={7}>
        {item?.masterName}
      </Text>
    
      </UnstyledButton>
   
  ));


  
   const filteredOptions = stateData?.filter((itemState) => itemState?.masterName?.toLowerCase().includes(search.toLowerCase().trim()))

   const options = filteredOptions?.map((itemState) => (
    <Combobox.Option onClick={()=>{handleSubmit2(itemState)}} value={itemState?.masterName} key={itemState?.id}>
      {itemState?.masterName}
    </Combobox.Option>
  ));
  

  

 



  return (

    <div>

<Container fluid bg="white" p="lg" style={{borderBottom:"0.5px solid #D2D2D2"}}>
       <Group align="center">
                          <Flex justify="start" style={{minWidth:"0%"}}>
                            <IoArrowBack onClick={arrowBack} color='black' size={26} style={{ cursor:"pointer"}} />
                          </Flex>
                          <span style={{fontWeight:"bold", flex:"1", textAlign:"center"}}>Location Information</span>
                          
          </Group>
          </Container>

     {!allowShow && (
      <Paper radius="md" p="xl" withBorder>
<Center>
<Text size="lg" fw={500}>
<Trans>Allow Your Location</Trans>
</Text>
</Center>

<Center>
<div style={{ marginTop: "30px" }}>
<Text size="md" fw={500} style={{textAlign:"center"}}>
<Trans>Allow ShramIN Jobs App to access to this device's location</Trans>
</Text>
</div>
</Center>

         <Center>
         <div style={{ marginTop: "30px" }}>
            <Button
              onClick={allowJobLocationFun}>
              <Text c="white" size="sm" mb={5} mt={5} fw={600}>
                  <Trans>Allow</Trans>
              </Text>
            </Button>
          </div>
         </Center>

         <Center>
         <div style={{ marginTop: "10px" }}>
            <Button
              onClick={notAllowJobLocationFun}>
              <Text c="white" size="sm" mb={5} mt={5} fw={600}>
                <Trans>Deny</Trans>
              </Text>
            </Button>
          </div>
         </Center>

</Paper>
)}
   
  
   {allowShow && (
        <Card withBorder radius="md" className={classes.card}>
        <Center>
            <Text mt={10} size="xl" fw={500}>
              <Trans>Where do you want job?</Trans>
            </Text>
          </Center>


          <Center style={{marginTop: "20px",}}>
        <Text style={{textAlign: "center"}}
            size="lg" mt={2} mb={2}>
               <Trans>Search State</Trans>
        </Text>
        </Center>
       


    <Combobox
      store={combobox}
      withinPortal={false}
      onOptionSubmit={(val) => {
        setValue(val);
        setSearch(val);
        closeSearch();
        // combobox.closeDropdown();
      }}
    >
      <Combobox.Target>
        <InputBase
          rightSection={<Combobox.Chevron />}
          value={search}
          onChange={(event) => {
            combobox.openDropdown();
            combobox.updateSelectedOptionIndex();
            setSearch(event.currentTarget.value);
          }}
          onClick={() => openSearch()}
          // onFocus={() => openSearch()}
          onBlur={() => {
            closeSearch()
            setSearch(value || '');
          }}
          placeholder={t`Search State`}
          rightSectionPointerEvents="none"
        />
      </Combobox.Target>

      <Combobox.Dropdown style={{zIndex:"0"}}>
        <Combobox.Options>
          {options?.length > 0 ? options : <Combobox.Empty><Trans>State not found</Trans></Combobox.Empty>}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
         

          

        <SimpleGrid cols={3} mt="md">
          {items}
        </SimpleGrid>

        {searchHeight ? 
        (
        <div style={{marginTop : "850px", fontWeight: "lighter"}}>
           <Text>.</Text>
        </div>
        )
        : 
        (<div>

        </div>
        )}



      </Card> 
   )} 
      </div>
          
  )
}

export default JobLocation