
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {getAuth} from 'firebase/auth'


export const apiSlice = createApi({
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({
        baseUrl: import.meta.env.VITE_BASE_URL,
        prepareHeaders: async (headers, { getState }) => {
                  const auth = getAuth();
                  const token =  await auth.currentUser.getIdToken()
                   
                
            headers.set('Authorization', `Bearer ${token}`);         
            headers.set('X-Custom-User-Agent', `1.0/1 ShramIN PWA`);
            return headers;
        },

    }),  
    tagTypes: ['Candidate'],

    endpoints: (builder) => ({
        getProfileDetails: builder.query({
            query: (id) => `/candidate?userId=${id}`,
            providesTags: ['Candidate']
        }),
        editProfileDetails: builder.mutation({
            query: (data) => ({
                url: '/candidate',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['Candidate']
        }),

        activeJobCategory: builder.mutation({
            query: (data) => ({
                url: '/candidate',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['Candidate']
        }),

        activeLocationCategory: builder.mutation({
            query: (data) => ({
                url: '/candidate',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['Candidate']
        }),

        getTradeDetails: builder.query({
            query: () => `master?sort=true&masterTypeId=1`,
        }),
        getEducationDetails: builder.query({
            query: () => `master?sort=true&masterTypeId=2`,
        }),
        getExperienceDetails: builder.query({
            query: () => `master?sort=true&masterTypeId=3`,
        }),
        getActiveTradeDetails: builder.query({
            query: () => `public/jobs/trade`,
        }),
        getActiveLocationDetails: builder.query({
            query: () => `public/jobs/job-openings/state`,
        }),
        getWorkTypeDetails: builder.query({
            query: () => `master?sort=true&masterTypeId=4`,
        }),
        getCurrentSalary: builder.query({
            query: () => `master?sort=true&masterTypeId=5`,
        }),
        getExpSalary: builder.query({
            query: () => `master?sort=true&masterTypeId=6`,
        }),
        getLocation: builder.query({
            query: () => `master?sort=true&masterTypeId=7`,
        }),
        getCollegeDetails: builder.query({
            query: (id) => `candidate/college-institutes?district=${id}`,
        }),
        getJobDescDetails: builder.query({
            query: (jobid) =>
                `/public/jobs/${jobid}`,
        }),

        getSimilarJobs: builder.query({
            query: (args) => {
                const { jid } = args
                return {
                    url: `/jobs/search/recommendation?jobId=${jid}`,
                }
            }
        }),

         // getBestMatchedJobs: builder.query({
        //     query: (candidateId) => `/jobs?candidateId=${candidateId}&page=0&size=35`
        // }),

        // getPrivateJob: builder.query({
        //     query: (candidateId) =>{

        //         //const { candidateId } = args
        //         return {
        //             if(candidateId != null){
        //                 url: `/jobs?candidateId=${candidateId}&page=0&size=210`,
        //             }
                   
        //         }
                
        //     } 
        // }),

        getBestMatchedJobs: builder.query({
            query: (candidateId) => {
                if (candidateId != null) {
                    return {
                        url: `/v2/jobs?candidateid=${candidateId}&page=0&size=10`,
                    };
                }
            }
        }),

        getPrivateJob: builder.query({
            query: (candidateId) => {
                if (candidateId != null) {
                    console.log(candidateId, "candidateId is in appSlice");
                    return {
                        url: `/v2/jobs?candidateid=${candidateId}&page=0&size=210`,
                    };
                }
            }
        }),
        
        getwalkinjobs: builder.query({
            query: (candidateId) => `/jobs?candidateId=${candidateId}&jobPostingTypeId=9732&page=0&size=100`
        }),
        getgovtjobs: builder.query({
            query: (candidateId) => `/jobs?candidateId=${candidateId}&jobPostingTypeId=119&page=0&size=100`
        }),
        getAllJobs: builder.query({
            query: () => '/all-jobs'
        }),
        uploadResume: builder.mutation({
            query: (file) => {
                const formData = new FormData();
                formData.append('textResume', file);
                return {
                    url: '/candidate/textResume',
                    method: 'POST',
                    body: formData,
                };
            },
            invalidatesTags: ['Candidate']
        }),
        postJobApply: builder.mutation({
            query: (data) => ({
                url: '/job-applications',
                method: "POST",
                body: data,
            })
        }),

        getjobapplystatus: builder.query({
            query: (args) => {
                const { jid, candidateid } = args
                return {
                    url: `/jobs/applied-job?jobId=${jid}&candidateId=${candidateid}`,
                }
            }
        }),
       

        getStateDetails: builder.query({
            query: () => `/candidate/state`,
        }),
        getDistrictDetails: builder.query({
            query: (id) => `candidate/district?state=${id}`,
        }),
        addEducation: builder.mutation({
            query: ({ candidateId, data }) => ({
                url: `/candidate/${candidateId}/multiple-education`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['Candidate']


        }),
        addExperience: builder.mutation({
            query: ({ candidateId, data }) => ({
                url: `/candidate/${candidateId}/multiple-experience`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['Candidate']


        }),
        editEducation: builder.mutation({
            query: ({ educationId, data }) => ({
                url: `/candidate/multiple-education/${educationId}`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['Candidate']
        }),
        editExperience: builder.mutation({
            query: ({ experienceId, data }) => ({
                url: `/candidate/multiple-experience/${experienceId}`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['Candidate']
        }),
        deleteEducation: builder.mutation({
            query: (eduId) => ({
                url: `/v2/candidate/multiple-education/${eduId}`,
                method: 'POST',
            }),
            invalidatesTags: ['Candidate']
        }),
        deleteExperience: builder.mutation({
            query: (expId) => ({
                url: `/v2/candidate/multiple-experience/${expId}`,
                method: 'POST',
            }),
            invalidatesTags: ['Candidate']
        }),
        candidateSignUp: builder.mutation({
            query: (fcmToken) => ({
                url: `/candidate/signup?fcmToken=${fcmToken}`,
                method: 'POST',
            }),
            invalidatesTags: ['Candidate']
        }),
        getAllCourses: builder.query({
            query: () => `/courses?page=0&size=100`
        }),
        uploadPhoto: builder.mutation({
            query: (formdata) => ({
                url: `/candidate/photo`,
                method: 'POST',
                body: formdata
            }),
            invalidatesTags: ['Candidate']
        }),
        uploadVideo: builder.mutation({
            query: (formdata) => ({
                url: `/candidate/videoResume`,
                method: 'POST',
                body: formdata
            }),
            invalidatesTags: ['Candidate']
        }),


        getMoreJobs: builder.query({
            query: (args) => {
                const { jid } = args
                return {
                    url: `/jobs/${jid}/employer-jobs`,
                }
            }
        }),


        getCourseDetail: builder.query({
            query: (cid) =>
                `/courses/${cid}`,
        }),
        postCourseApply: builder.mutation({
            query: (data) => ({
                url: '/courses-applications',
                method: "POST",
                body: data,
            })
        }),
        getcourseapplystatus: builder.query({
            query: (args) => {
                const { courseid, candidateid } = args
                return {
                    url: `/courses/applied-course?courseId=${courseid}&candidateId=${candidateid}`,
                }
            }
        }),
        searchSuggestions: builder.query({
            query: (searchText) => {
                return {
                    url: `/jobs/search/suggestion?searchText=${searchText?.value}`,
                }
            }
        }),
        searchResults: builder.query({
            query: (args) => {
                const { candidateId, searchParamsData } = args
                return {
                    url: `/all-jobs/search?candidateId=${candidateId}&searchText=${searchParamsData}`,
                }
            }
        }),
        getshraminshalavideos: builder.query({
            query: () => {
                return {
                    url: "/video"
                }
            }
        }),
        getplayvideos: builder.query({
            query: () => {
                return {
                    url: "/candidate/multiple-video?page=0&size=100"
                }
            }
        }),
        getReportJob: builder.query({
            query: () => `master?sort=true&masterTypeId=21`,
        }),
        postReportJob: builder.mutation({
            query: (data) => ({
                url: '/job/report',
                method: "POST",
                body: data,
            })
        }),
        getJobAppliedDetail: builder.query({
            query: (args) => {
                const { candidateid } = args
                return {
                    url: `/v2/jobs?candidate=${candidateid}&page=0&size=100`,
                }
            }
        }),
        getHomeTopBanner: builder.query({
            query: () => `/candidate/banner`,
        }),

        getStateJobs: builder.query({
            query: (stateId) => `/job/category?stateId=${stateId}&page=0&size=10`
        }),

        getSalaryJobs: builder.query({
            query: (salaryId) => `/job/category?salaryId=${salaryId}&page=0&size=10`
        }),

        getExperienceJobs: builder.query({
            query: (experienceId) => `/job/category?experienceId=${experienceId}&page=0&size=10`
        }),

        getFresherJobs: builder.query({
            query: () => `/job/category?experienceId=30&page=0&size=10`
        }),

        getStateJobsForAll: builder.query({
            query: (stateId) => `/job/category?stateId=${stateId}&page=0&size=100`
        }),

        getSalaryJobsForAll: builder.query({
            query: (salaryId) => `/job/category?salaryId=${salaryId}&page=0&size=100`
        }),

        getExperienceJobsForAll: builder.query({
            query: (experienceId) => `/job/category?experienceId=${experienceId}&page=0&size=100`
        }),

        getActiveTradeProfile: builder.query({
            query: () => `master?masterTypeId=1&sort=true&active=true`,
        }),

        postSendEmailLink: builder.mutation({
            query: (data) => ({
                url: '/candidate/create-email-link',
                method: "POST",
                body: data,
            })
        }),

        getVerifyEmail : builder.query({
            query: (token) => `/public/candidate/verify-email-link/${token}`
        })
        

    }),

});



export const { useGetProfileDetailsQuery, useGetTradeDetailsQuery, useGetEducationDetailsQuery, useGetExperienceDetailsQuery, useGetActiveTradeDetailsQuery, useGetActiveLocationDetailsQuery, useEditProfileDetailsMutation, useActiveJobCategoryMutation, useActiveLocationCategoryMutation, useGetBestMatchedJobsQuery, useGetAllJobsQuery, useGetPrivateJobQuery, useGetgovtjobsQuery, useGetwalkinjobsQuery, useUploadResumeMutation, useGetJobDescDetailsQuery, useGetSimilarJobsQuery, usePostJobApplyMutation, useGetjobapplystatusQuery, useGetCollegeDetailsQuery, useGetStateDetailsQuery, useGetDistrictDetailsQuery, useAddEducationMutation, useGetCurrentSalaryQuery, useGetExpSalaryQuery, useGetLocationQuery, useGetWorkTypeDetailsQuery, useAddExperienceMutation, useEditEducationMutation, useEditExperienceMutation, useDeleteEducationMutation, useDeleteExperienceMutation, useCandidateSignUpMutation, useGetAllCoursesQuery, useUploadPhotoMutation, useGetMoreJobsQuery, useGetCourseDetailQuery, usePostCourseApplyMutation, useGetcourseapplystatusQuery, useSearchSuggestionsQuery, useSearchResultsQuery, useGetshraminshalavideosQuery, useGetplayvideosQuery, useUploadVideoMutation, useGetReportJobQuery, usePostReportJobMutation, useGetJobAppliedDetailQuery, useGetHomeTopBannerQuery, useGetStateJobsQuery, useGetSalaryJobsQuery, useGetExperienceJobsQuery, useGetFresherJobsQuery, useGetStateJobsForAllQuery, useGetSalaryJobsForAllQuery, useGetExperienceJobsForAllQuery, useGetActiveTradeProfileQuery, usePostSendEmailLinkMutation, useGetVerifyEmailQuery } = apiSlice;



