import { useQuery, keepPreviousData, useQueryClient, useMutation } from "@tanstack/react-query";

export function useGetAllNotification(candidateId, token) {

  const url = import.meta.env.VITE_BASE_URL + '/notifications/candidate/';

  const query = useQuery({
    queryKey: ["allNotification"],
    queryFn: async () =>
      // fetch('https://shramin-stag.azurewebsites.net/notifications/candidate/8a4eab65-6749-4725-ad36-c11f14a662b9', {
      fetch(`${url}${candidateId}`, {
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${token}`,
          "Accept": 'application/json'
        },
      }).then((res) => {
        if (!res.ok) {
          throw new Error('Network response was not ok');
        }
        return res.json();

      }),

    //   return await response.data;

    //placeholderData: keepPreviousData,
  });
  return query;

  ////////////
}




export function useCreateLike() {
  const url = import.meta.env.VITE_BASE_URL + '/candidate/multiple-video/interaction';

  const mutation = useMutation({
    queryKey: ["likeDislike"],
    mutationFn: async ({ likedata, token, candidateId, candidateVideoId }) => {
      console.log("1st", likedata, token, candidateId, candidateVideoId)
      const obj = {
        candidateId: likedata?.candidateId,
        candidateVideoId: likedata?.candidateVideoId,
        viewed: true,
        liked: likedata?.liked,
        disliked: likedata?.disliked,
        shared: null
      };

      const response = await fetch(`${url}?candidateId=${candidateId}&candidateVideoId=${candidateVideoId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json',
        },
        body: JSON.stringify(obj),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const likeDetailData = await response.json();
      return likeDetailData;
    },

    onError: (error) => {
      console.error('Error fetching like detail:', error);
    },

    onSuccess: (data) => {
      console.log('Success:', data);
    },
  });

  return mutation;
}



// export function useCreateLike() {
//   const queryClient = useQueryClient();

//   const url = import.meta.env.VITE_BASE_URL + '/candidate/multiple-video/interaction';

//   return useMutation({
//     mutationKey: ['createLike'],
//     mutationFn: async ({ likedataa, candidateId, candidateVideoId, token }) => {
//       const fullUrl = `${url}?candidateId=${candidateId}&candidateVideoId=${candidateVideoId}`;

//       return fetch(fullUrl, {
//         method: "POST",
//         headers: {
//           "Authorization": `Bearer ${token}`,
//           "Content-Type": "application/json",
//           "Accept": "application/json",
//         },
//         body: JSON.stringify(likedataa),
//       }).then((res) => {
//         if (!res.ok) {
//           throw new Error('Network response was not ok');
//         }
//         return res.json();
//       });
//     },
//   });
// }