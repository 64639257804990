import React from 'react';
import {
  Card,
  Text,
  Badge,
  UnstyledButton,
  Grid,
} from '@mantine/core';
import { IoArrowBack } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import { Trans } from '@lingui/macro';
import classes from '../../styles/ActionHomeSection.module.css';
import shopkeeperimg from '../../../public/shopkeeper.png' 
import basicimg from '../../../public/conversation.png' 
import hrinterviewimg from '../../../public/job-interview.png' 
import sportsimg from '../../../public/sports.png' 

const mockdata = [
  { title: <Trans>Basics</Trans>, color: 'black', icon: basicimg},
  { title: <Trans>Sports</Trans>, color: 'black', icon: sportsimg},
  { title: <Trans>HR Interview</Trans>, color: 'black', icon: hrinterviewimg},
  { title: <Trans>Shopkeeper</Trans>, color: 'black', icon: shopkeeperimg }
];

const LeanEnglish = () => {
  const navigate = useNavigate();
  const arrowBack = () => navigate('/tutorwheel');
  const arrowNext = () => navigate('/aitutor');
  const arrowNextSport = () => navigate('/aisport');
  

  // const handleRedirect = (link) => {
  //   console.log("linkccc", link)
  //   navigate(link)
  // }

  const items = mockdata.map((item) => (
    <UnstyledButton
      style={{
        marginLeft: "10px",
        marginRight: "10px",
        boxShadow: "rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px",
      }}
      key={item.title}
      // onClick={() => { handleRedirect(item.link) }}
      // onClick={arrowNext}
      className={classes.item}
    >
      <img src={item.icon} alt="icon" style={{ height: "70px", width: "70px", color:"black" }} />
      <Text mt={7} style={{ textAlign: "center",color:"black", fontSize: "14px" }}>
        {item.title}
      </Text>
    </UnstyledButton>
  ));

  return (
    <div>

<div style={{
        position: "fixed",
        width: "100%",
        height: "8%",
        // borderRadius: "30px",
        maxWidth: "600px",
        top: "55px",
        zIndex:"1",
        // background: "white",
        padding: "0px",
        display: "grid",
        alignItems: "center",
        justifyContent: "'center",
        alignContent: "center",
        backgroundColor: "white"
      }}>



        <div>
          <IoArrowBack onClick={arrowBack} color='black' size={26} style={{ marginLeft: "10px", marginTop: "5px" }} />
        </div>
        <div style={{ marginTop: "-40px", marginLeft: "50px", marginRight: "50px", textAlign: "center", alignItems: "center", justifyContent: "center" }}>
          <Text style={{ fontWeight: "bold", fontSize: "20px", textAlign: "center" }}>
          <Trans>Select your scenario</Trans>
          </Text>
        </div>
      </div>

      
      <div style={{marginTop: "100px", marginRight:"0px", margin:"0px"}}>

      
      <Grid
  gutter={{ base: 5, xs: 'md', md: 'md', xl: 30 }}
  style={{
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '50vh',
    paddingTop: '60px',
    paddingLeft: '10px',
    paddingRight: '10px',
    marginLeft: '10px',
    marginRight: '10px',
    alignItems: items.length < 3 ? 'center' : 'flex-start',
  }}
>
  {items.map((option, index) => (
    <Grid.Col
      key={index}
      span={6}
      style={{ paddingTop: '15px', display: 'flex', justifyContent: 'center' }}
    >

{index === 0 ? (
<>
<Card
        style={{
          borderWidth: '1px',
          border: '0.5px solid #bbbbbc',
          borderRadius: '10px',
          padding: '20px',
          textAlign: 'center',
          height: '100%',
          width: '100%',
          display: 'flex',
          color: 'black',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: "pointer"
        }}
        onClick={arrowNext}
      >
        <Text style={{ marginTop: '10px', fontSize: '14px', color: 'black' }}>
          {option.props.children}
        </Text>
      </Card>
</>
) : (
  <>
  {index === 1 ? (
<>
<Card
            style={{
              borderWidth: '1px',
              border: '0.5px solid #bbbbbc',
              borderRadius: '10px',
              padding: '20px',
              textAlign: 'center',
              height: '100%',
              width: '100%',
              display: 'flex',
              color: 'black',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: "pointer"
            }}
             onClick={arrowNextSport}
          >
            
            <Text style={{ marginTop: '10px', fontSize: '14px', color: 'black' }}>
              {option.props.children}
            </Text>
          </Card>

</>) : (
    <>
    <Card
            style={{
              borderWidth: '1px',
              border: '0.5px solid #bbbbbc',
              borderRadius: '10px',
              padding: '20px',
              textAlign: 'center',
              height: '100%',
              width: '100%',
              display: 'flex',
              color: 'black',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: "pointer"
            }}
            // onClick={arrowNext}
          >
            {index !== 0 && (
              <Badge style={{ marginTop: '-15px' }} variant="light" color="blue" size="sm" radius="sm">
                <Trans>Coming Soon</Trans>
              </Badge>
            )}
            <Text style={{ marginTop: '10px', fontSize: '14px', color: 'black' }}>
              {option.props.children}
            </Text>
          </Card>
          </>

)}
</>

)}
      
    </Grid.Col>
  ))}
</Grid>
</div>


      <div style={{ marginTop: "60px" }}>
        <h6 style={{ color: 'white' }}>.</h6>
      </div>
    </div>
  );
};

export default LeanEnglish;
