import React, { useState, useEffect } from 'react';
import { FcLike, FcShare } from "react-icons/fc";
import { SlDislike } from "react-icons/sl";
import { useCreateLike } from '../../services/Queries';
import { useSelector } from 'react-redux';
import { BiSolidDislike } from "react-icons/bi";
import { IoHeart } from "react-icons/io5";




const LikeShareButtons = ({ candidateName, likesTotal, candidateVideoId, disLikesTotal, candidateLikeVideo, candidateDislikeVideo }) => {
  const token = useSelector((state) => state?.user?.token) || '';
  const candidateId = localStorage.getItem('candidateId');
  const [liked, setLiked] = useState(false);
  const [disLiked, setDisLiked] = useState(false);
  const [likes, setLikes] = useState(likesTotal ? likesTotal : 0);
  const [disLikes, setDisLikes] = useState(disLikesTotal ? disLikesTotal : 0);
  const mutation = useCreateLike()

  //console.log("llllllllllll", liked, candidateLikeVideo, candidateDislikeVideo)

  useEffect(() => {
    if (candidateLikeVideo) {
      let isLiked = candidateLikeVideo?.some(item => candidateId === item?.id);
     // console.log("aaaaaaaaaaatttttttt", isLiked);
      setLiked(isLiked);
    }
    //////////dislike///////
    if (candidateDislikeVideo) {
      let isDisliked = candidateDislikeVideo?.some(item => candidateId === item?.id);
      // console.log("bbbbbbbbbbtttttttt", isDisliked);
      setDisLiked(isDisliked);
    }
  }, [candidateLikeVideo, candidateDislikeVideo, candidateId]);

  const likedata = {
    candidateId,
    candidateVideoId,
    viewed: true,
    liked: true,
    disliked: null,
    shared: null
  }

  const dislikedata = {
    candidateId,
    candidateVideoId,
    viewed: true,
    liked: null,
    disliked: true,
    shared: null
  }

  const handleLike = () => {
    disLiked ? setDisLikes(disLikes - 1) : setDisLikes(disLikes)
    disLiked ? setDisLiked(!disLiked) : setDisLiked(disLiked)
    setLikes(likes + (liked ? -1 : 1));
    setLiked(!liked);
    mutation.mutate({ likedata, token, candidateId, candidateVideoId });
  };

  const handleDisLike = () => {
    liked ? setLikes(likes - 1) : setLikes(likes)
    liked ? setLiked(!liked) : setLiked(liked)
    setDisLikes(disLikes + (disLiked ? -1 : 1));
    setDisLiked(!disLiked);
    mutation.mutate({ likedata: dislikedata, token, candidateId, candidateVideoId });
  };

  const handleShare = () => {
    if (navigator.share) {
      navigator.share({
        title: 'Check this out!',
        text: 'You might find this interesting.',
        url: window.location.href,
      })
        .catch(error => console.error('Error sharing:', error));
    } else {
      alert('Sharing not supported on this browser.');
    }
  };

  const containerStyle = {
    position: 'relative',
    left: '85%',
    top: '-30vh',
    transform: 'translateY(-50%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '1px',
    borderRadius: '0px',
    zIndex: 1900, // Ensures it's on top of other content
  };

  const isMobile = window.innerWidth <= 480;
  const isTablet = window.innerWidth <= 768;

  const usernameStyle = {
    fontSize: '1rem',
    position: 'relative',
    top: '-22vh',
    right: isMobile ? '-6vh' : isTablet ? '-1vw' : '-1vw',
    zIndex: 1111,
    display: 'flex',
    color: '#007bff',
    fontWeight: 'bold',
    filter: 'drop-shadow(0 0 10px grey)'
  };

  const logo = {
    width: "25px",
    background: '#333',
    borderRadius: '70%',
    margin: "5px"

  }

  const buttonStyle = {
    display: 'flex',
    alignItems: 'start',
    background: 'none',
    border: 'none',
    // cursor: 'pointer',
    fontSize: '20px',
    margin: '1px 0',
    padding: '0px',
    borderRadius: '5px',
    transition: 'background 0.3s, color 0.3s',
    width: '100%', // Full width buttons
    textAlign: 'center',
  };

  const likedButtonStyle = {
    ...buttonStyle,
    color: liked ? 'red' : 'gray',
    transition: 'background 0.3s, color 0.3s',

  };

  const disLikedButtonStyle = {
    ...buttonStyle,
    color: disLiked ? 'blue' : 'gray',
    transition: 'background 0.2s, color 0.3s',

  };

  const shareButtonStyle = {
    ...buttonStyle,
    color: '#007bff',
    transition: 'background 0.3s, color 0.3s',
  };

  const likedCountStyle = {
    position: 'relative',
    right: '6%',
    top: '0%',
    color: 'white'
  }


  return (
    <>

      <div style={usernameStyle}>
        {/* <div style={logo}><img src="" alt="" /></div> */}
        <div>{candidateName}</div>
      </div>

      <div style={containerStyle}>

        <button onClick={handleLike} style={likedButtonStyle}>
          <IoHeart

            size={25}
            style={{
              cursor: 'pointer',
              transition: 'transform 0.3s ease-in-out',
              transform: liked ? 'scale(1.4)' : 'scale(1)',
              filter: liked ? 'drop-shadow(0 0 10px red)' : 'none',
              color: liked ? 'red' : 'gray',
            }}
            onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.2)'}
            onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}

          />
        </button>
        <div style={likedCountStyle}>{likes}</div>
        <button onClick={handleDisLike} style={disLikedButtonStyle}>
          <BiSolidDislike

            size={24}
            style={{
              cursor: 'pointer',
              transition: 'transform 0.3s ease-in-out',
              transform: disLiked ? 'scale(1.2)' : 'scale(1)',
              filter: disLiked ? 'drop-shadow(0 0 10px blue)' : 'none',
              color: disLiked ? 'blue' : 'gray',
            }}
            onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.1)'}
            onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}

          />
        </button>
        {/* <div style={likedCountStyle}>{disLikes}</div> */}
        <div style={{marginBottom:"5px"}}></div>



        <button onClick={handleShare} style={shareButtonStyle}>
          <FcShare
            size={25}
            style={{
              cursor: 'pointer',
              transition: 'transform 0.2s ease-in-out',
              transform: 'scale(1)',
            }} onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.2)'}
            onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}

          />
        </button>

      </div></>
  );
};

export default LikeShareButtons;